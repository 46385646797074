@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: HKGrotesk-Regular;
    font-weight: 300;
    src: url("./fonts/HKGrotesk-Light.woff") format("woff");
  }
  @font-face {
    font-family: HKGrotesk-Regular;
    font-weight: 400;
    src: url("./fonts/HKGrotesk-Regular.woff") format("woff");
  }
  @font-face {
    font-family: HKGrotesk-Regular;
    font-weight: 500;
    src: url("./fonts/HKGrotesk-Medium.woff") format("woff");
  }
  @font-face {
    font-family: HKGrotesk-Regular;
    font-weight: 600;
    src: url("./fonts/HKGrotesk-SemiBold.woff") format("woff");
  }
  @font-face {
    font-family: HKGrotesk-Regular;
    font-weight: 700;
    src: url("./fonts/HKGrotesk-Bold.woff") format("woff");
  }
  @font-face {
    font-family: HKGrotesk-Regular;
    font-weight: 900;
    src: url("./fonts/HKGrotesk-Black.woff") format("woff");
  }
    @font-face {
    font-family: Inter;
    src: url("./fonts/Inter.ttf") format("truetype");
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

select, option {
  font-family: Inter;
}

/* This hides default icons on date input components and makes them clickable on whole input size */
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}